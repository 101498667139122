import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Notification } from "@src/Helpers/actions"
import { ls } from '@src/Helpers/LocalStorageEncrypt'
import { BASE_API } from "../../Api"

export const authMe = createAsyncThunk(
  "authMe",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await BASE_API.get("/admin/me")
    if (response?.status === 200) {
      return fulfillWithValue(response?.data)
    }
    return rejectWithValue(response)
  }
)

const initialState = {
  data: {},
  isLogin: false,
  permissions: [] 
}

export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
      updateProfile: (state, action) => {
        state.isLogin = true 
        state.data = action?.payload
      },
      handleLogout: (state) => {
        state.isLogin = false 
        state.data = {}
        ls.removeItem('token')
        state.permissions = []
        Notification('Logged-out Successfully', 2000, 'success') 
      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(authMe.fulfilled, (state, action) => {
        const { permissions } = action.payload
        state.permissions = permissions
      }) 
      .addCase(authMe.rejected, (state) => {
        Notification('Session Expired', 2000, 'warning') 
        state.isLogin = false 
        state.data = {}
        ls.removeItem('token')
        state.permissions = []
        window.location.pathname = '/login'
      }) 
    }
})

export const { updateProfile, handleLogout } = user.actions

export default user.reducer