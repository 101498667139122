import CVS_IMG from '@src/assets/media/FilesTypes/CSV.webp'
import PDF_IMG from '@src/assets/media/FilesTypes/pdf.webp'
import DEFAULT_IMG from '@src/assets/media/FilesTypes/DEFAULT.png'
import DOCX_IMG from '@src/assets/media/FilesTypes/docx.png'
import XLS_IMG from '@src/assets/media/FilesTypes/xls.webp'
import PPT_IMG from '@src/assets/media/FilesTypes/ppt.png'
import moment from "moment"
import toast from 'react-hot-toast'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as qs from "qs"
import { FaMale, FaFemale} from "react-icons/fa"
import { useSelector } from 'react-redux'

const MySwal = withReactContent(Swal)

export const SetActiveElement = (selector, className, defaultelement = undefined) => {
    const elements = document.querySelectorAll(`${selector}`)
    if (elements[defaultelement]) {
        elements[defaultelement].classList.add(className)
    }
    elements?.forEach((element) => {
        element.addEventListener("click", (e) => {
            elements.forEach((ele) => ele.classList.remove(`${className}`))
            e.target.classList.add(`${className}`)
        })
    })
}

export const calculateMonthDifference = (startDate, endDate) => {
    const str = moment(startDate)?.toDate()
    const end = moment(endDate)?.toDate()
    const startYear = str?.getFullYear() 
    const startMonth = str?.getMonth()
    const endYear = end?.getFullYear()
    const endMonth = end?.getMonth()

    const differenceInYears = endYear - startYear
    const differenceInMonths = endMonth - startMonth

    return (differenceInYears * 12) + differenceInMonths
}

let activeToastId = null

export const Notification = (text = "Please Fill All Fields", time = 2500, type = 'error', onClose, maxErrors = 2) => {
    const handleMessages = () => {
        if (typeof text === 'object') {
            return Object.values(text).map((message, index) => {
                if (typeof message === 'string' && index + 1 <= maxErrors) {
                    return <div style={{ width: '100%' }} key={`message_${index}`}>{message}</div>
                } else {
                    return "Please Fill All Fields"
                }
            })
        } else {
            return text
        }
    }

    if (!activeToastId) {
        activeToastId = toast.custom(<div>{handleMessages()}</div>, {
            duration: time,
            type,
            position: 'top-center',
            onClose: () => {
                activeToastId = null
                if (onClose && typeof onClose === 'function') {
                    onClose()
                }
            }
        })

        setTimeout(() => {
            toast.dismiss(activeToastId)
            activeToastId = null
        }, time + 100)
    }
} 
export function fileToUrl(file) {
    if (file) {
        const objectURL = URL?.createObjectURL(file)
        return objectURL
    }
}
export const getFileType = (file) => {
    const fileName = file?.name
    const fileType = fileName?.slice(fileName?.indexOf('.') + 1)
    return fileType
}

export const handleFileIcon = (file) => {
        switch (getFileType(file)) {
            case 'pdf':
                return PDF_IMG
            case 'jpg':
            case 'jpeg':
            case 'png':
                return fileToUrl(file)
            case 'ppt':
            case 'pptx':
                return PPT_IMG
            case 'doc':
            case 'docx':
                return DOCX_IMG
            case 'xls':
            case 'xlsx':
                return XLS_IMG
            case 'cvs':
                return CVS_IMG
            default:
                return DEFAULT_IMG 
        }
}

export const textTruncate = (text, maxLength = 10, concatinationWord = '...') => {
    const textLength = text?.length
    return text?.slice(0, maxLength) + (textLength >= maxLength ? concatinationWord : "")
}

export const formikValidator = (formikProps) => {
    const { values, errors } = formikProps 
    return new Promise((resolve, reject) => {
        if (Object.values(values)?.length && !Object.values(errors)?.length) {
            resolve()
        } else {
            reject(Notification(Object.values(errors)?.length ? errors : "Please Fill All Fields"))
        }
    })
}

export const blockButton = () => {
const button = document.querySelector('button[type="submit"]')
    if (button) {
        button?.classList?.remove('openButton')
        button?.classList?.add('blockButton')
        button.disabled = true 
    }
}
export const openButton = () => {
    const button = document.querySelector('button[type="submit"]')
    if (button) {
        button?.classList?.remove('blockButton')
        button?.classList?.add('openButton')
        button.disabled = false 
    }
}

export const getName = (str) => {
    const results = []
    const wordArray = str?.split(" ") || []
    wordArray.forEach((e) => {
        results.push(e[0])
    })
    return results.join("")?.slice(0, 2)
}


const getClassNameByType = (type) => {
type = type === 'error' ? 'danger' : type === 'question' ? 'info' : type
const types = ['success', 'warning', 'danger', 'info']
return types[type] ? `text-${types[type]}` : 'text-warning'
}

export const handleConfirmCancel = (
    title,
    text = '',
    type = 'warning',
    confirmCallback = () => false,
    cancelCallback = () => false,
    buttons = { confirm: 'Yes', cancel: 'No' }
) => {
    const selectedClass = getClassNameByType(type)

    return MySwal.fire({
    title: `<span class="${selectedClass} fs-3">${title}</span>`,
    html: text,
    //text: text,
    icon: type ? type : false,
    showCancelButton: !!buttons.cancel,
    showConfirmButton: !!buttons.confirm,
    confirmButtonText: buttons.confirm ? buttons.confirm : '',
    cancelButtonText: buttons.cancel ? buttons.cancel : '',
    customClass: {
        confirmButton: 'btn btn-outline-secondary',
        cancelButton: 'btn btn-outline-secondary ms-1'
    },
    buttonsStyling: false
    }).then(function (result) {
        if (result && result.value) {
            confirmCallback()
        } else if (result.dismiss === MySwal.DismissReason.cancel) {
            cancelCallback()
        }
    })
}

export const filterBuilder = (params, useQueryString = true, removeKeys = []) => {
    const result = {}
    try {
        const { filters, ...restParams } = params
        for (const key in filters) {
            if (!removeKeys?.includes(key)) {
            if (key && key.indexOf(";") !== -1) {
                key.split(";").map((_k) => {
                if (_k && _k.length) {
                    result[_k] = filters[key]
                }
                })
            } else {
            result[key] = filters[key]
            }
            }
        }
        if (useQueryString) {
            return qs.stringify({
            ...restParams,
            filter: result
            })
        }
        } catch (error) {}
        return result
    }

    function appendFormData(formData, key, value) {
        if (value instanceof File) {
            formData.append(key, value)
        } else if (value instanceof Date) {
            formData.append(key, value.toISOString())
        } else if (typeof value === "boolean") {
            formData.append(key, value ? "true" : "false")
        } else {
            formData.append(key, value.toString())
        }
    }

    export function convertToFormData(obj, formData = new FormData(), parentKey = "") {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
            const value = obj[key]
            const propName = parentKey ? `${parentKey}[${key}]` : key
            if (value === null || value === undefined) {
                formData.append(propName, "")
            } else if (
                typeof value === "object" &&
                !(value instanceof File) &&
                !(value instanceof Date)
            ) {
                convertToFormData(value, formData, propName)
            } else if (Array.isArray(value)) {
                value.forEach((item, index) => {
                const arrayKey = `${propName}[${index}]`
                appendFormData(formData, arrayKey, item)
            })
            } else {
                appendFormData(formData, propName, value)
            }
        }
        }
        return formData
      }

    export const handleGender = (gender) => {
        switch (gender) {
            case '1':
            return <div><FaMale size={32} color='green'/> Male</div>
        
            case '0':
            return <div><FaFemale size={32} color='#ff9f43'/> Female</div>
        
            default:
            break
        }
    } 

export const roundToTwoDecimals = (num, cells = 2) => {
    if (num) {
        return parseFloat(num.toFixed(cells))
    } else {
        return 0
    }
}

export const stringToNumber = (string = '', separator = ',') => {
    return +string.replaceAll(separator, '')
}

export const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const handleProfitColor = (profit, base = 0) => {
    if (profit === base) {
        return ''
    } else if (profit > base) {
        return 'text-success'
    } else {
        return 'text-danger'
    }
}

export const handleProfitOperator = (profit, base = 0) => {
    if (+profit === base) {
        return ` ${  profit}`
    } else if (profit > base) {
        return `+ ${  profit}`
    } else {
        return `- ${profit * -1}`
    }
}

export const getOrdinal = (index) => {
    // تأكد من أن الفهرس هو عدد صحيح وغير سلبي
    if (isNaN(index) || index < 1 || !Number.isInteger(index)) {
        return 0
    }

    // تحديد المقطع (suffix) بناءً على قيمة الفهرس
    const lastDigit = index % 10
    const lastTwoDigits = index % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return `${index}th`
    }

    switch (lastDigit) {
        case 1:
            return `${index}st`
        case 2:
            return `${index}nd`
        case 3:
            return `${index}rd`
        default:
            return `${index}th`
    }
}

export const Can = ({ action, resource, component, noActions = <>No Actions.</> }) => {
    const { permissions } = useSelector(state => state?.user)

    if (permissions?.includes(`${action}-${resource}`)) {
        return component
    } else {
        return noActions
    }

}