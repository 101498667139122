import { ls } from '@src/Helpers/LocalStorageEncrypt'
import axios from 'axios'
import toast from 'react-hot-toast'

// const API_URL = 'http://localhost:8000/api'
const API_URL = 'https://api.auxilium-software.com/api'

    const getToken = () => {
        const token = ls.getItem("token")
        return token
    }

    const getCrudHeaders = () => {
    const token = getToken()
    if (token) {
        return {
        Authorization: `bearer ${token}`,
        'Content-Type': "multipart/form-data"
        }
    } else {
        return {
        'Content-Type': "multipart/form-data"
        }
    }
    }
    const getBaseHeaders = () => {
    const token = getToken()
    if (token) {
        return {
        Authorization: `bearer ${token}`,
        'Content-Type': "application/json"
        }
    } else {
        return {
        'Content-Type': "application/json"
        }
    }
    }

    export const CRUD_API = axios.create({
        baseURL: API_URL,
        headers: getCrudHeaders()
    })
    export const BASE_API = axios.create({
        baseURL: API_URL,
        headers: getBaseHeaders()
    })

    const handleErrors = () => {
        // if (code === 401) {
        //     store.dispatch(handleLogout({customMessage : Notification('Session Expired', 2000, 'error') }))
        // }
        // console.log(code)
    }
    
    CRUD_API.interceptors.request.use((res) => {
        let hasFile = false
        if (res?.data) {
            Object?.values(res?.data)?.map((item) => {
                if (item instanceof File || item instanceof Blob) {
                    hasFile = true
                }
            })
        }
        if (hasFile) {
            const uploadToast = toast.loading('Upload in progress...')
            res.onUploadProgress = (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                if (progress < 100) {
                    toast(`Upload Progress: ${progress}%`, { id: uploadToast })
                } else {
                    toast.remove(uploadToast)
                }
            }
        }
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })

    CRUD_API.interceptors.response.use((res) => {
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })

    BASE_API.interceptors.response.use((res) => {
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })