// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import user from '@src/Pages/Auth/store'
import authMe from '../Pages/Auth/store'

export const reducers = {
  navbar,
  layout,
  user,
  authMe
}
